import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import _get from 'lodash/get';

import Toggle from '../../../../app/components/toggle';
import SectionHeader from '../../components/sectionHeader';
import TextField from '../../../../app/components/textField';
import SelectField from '../../../../app/components/selectField';

import './style.css';

class AppLinkMenu extends Component {
  static propTypes = {
    link: PropTypes.object,
    pages: PropTypes.array.isRequired,
    borderTop: PropTypes.bool,
    linkOptional: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    createLink: PropTypes.func.isRequired,
    showLabel: PropTypes.bool,
  };

  static defaultProps = {
    borderTop: false,
    linkOptional: true,
    link: {
      enabled: false,
    },
    showLabel: true,
  };

  onLabelChange = value =>
    value.length < 21 && this.handleChange(['link', 'label'])(value);

  handleOptionElChange = prop => (e, isToggled) =>
    this.props.onChange(prop, isToggled);

  createLink = f => this.props.createLink(f);

  handleLinkChange = (index, payload) =>
    this.handleChange(['link', 'linkType', 'page'])(payload);

  handleChange = prop => value => this.props.onChange(prop, value);

  handleRadioChange = prop => (f, value) => this.props.onChange(prop, value);

  isLinkDisabled = () => {
    const { linkOptional, link } = this.props;
    return linkOptional && (!link || !link.enabled);
  };

  isLableDisabled = () => this.isLinkDisabled();

  hasToggleToEnableLink = () =>
    !this.props.linkOptional || (
      <Toggle
        onToggle={(e, isToggled) => {
          if (this.props.link) {
            this.handleOptionElChange(['link', 'enabled'])(e, isToggled);
          } else {
            this.createLink();
          }
        }}
        toggled={(this.props.link && this.props.link.enabled) || false}
      />
    );

  dropdownNavigationOptions = () =>
    this.props.pages.map(
      (page, index) =>
        page.type !== 'Popup' && (
          <MenuItem
            key={page.path}
            value={index ? page.path : '/'}
            primaryText={page.title}
          />
        ),
    );

  render() {
    const { link, borderTop, showLabel, t } = this.props;
    const linkType = {
      page: '/',
      selectedOption: 'page',
      ..._get(link, 'linkType'),
    };

    return (
      <div className="app-link-menu-wrapper">
        <SectionHeader
          title="Navigation Link"
          actions={this.hasToggleToEnableLink()}
          tooltip={t('Allow users to navigate to another page')}
          borderTop={borderTop}
        />
        {showLabel && (
          <TextField
            id="app-navigation-label"
            value={link && link.label}
            onChange={this.onLabelChange}
            placeholder="Link Label"
            disabled={this.isLableDisabled()}
          />
        )}
        <div className="app-link-container">
          <RadioButtonGroup
            className="radio-wrapper"
            name="buttonLink"
            valueSelected={linkType.selectedOption}
            onChange={this.handleRadioChange([
              'link',
              'linkType',
              'selectedOption',
            ])}
          >
            <RadioButton
              id="page"
              value="page"
              disabled={this.isLinkDisabled()}
            />
            <RadioButton
              id="web"
              value="web"
              disabled={this.isLinkDisabled()}
            />
          </RadioButtonGroup>
          <div className="link-radio-option-wrapper">
            <SelectField
              label="Select a page"
              value={linkType.page}
              options={this.dropdownNavigationOptions()}
              onChange={this.handleLinkChange}
              disabled={
                this.isLinkDisabled() || linkType.selectedOption !== 'page'
              }
            />
            <TextField
              id="custom-app-webpage"
              value={link && link.linkType && link.linkType.web}
              onChange={this.handleChange(['link', 'linkType', 'web'])}
              placeholder="Webpage Link"
              disabled={
                this.isLinkDisabled() || linkType.selectedOption !== 'web'
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AppLinkMenu;
